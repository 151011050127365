// Custom additions to Foundation base

/* Top Bar Nav */
.top-bar {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  background: none;


  .top-bar-right {
    justify-content: flex-end;
  }

  // a {
  //   color: #0a0a0a;
  // }
}

.off-canvas-branding {
  margin-top: 4rem;
}

.off-canvas-nav {
  margin-top: 2rem;
}

.menu {
  display: flex;
  align-items: center;
  // margin-top: 4rem;
}

.sticky-container {
  // height: 0;
}

.sticky {
  // width: 100%
}

// Hero Text

.hero-container {
  text-align: left;
  height: 100vh;
  background-size: cover;

  h1 {
    font-family: $header-font-family;
    font-size: rem-calc(56);
    // text-transform: uppercase;
    font-variant-caps: all-small-caps;
    letter-spacing: 0.10em;
    line-height: 0.8;
    color: #444;
    position: absolute;
    right: 1em;
    bottom: 20%;
  }

  // Styling <span class="artist"> on homepage feature
  .artist {
    font-size: 0.6em;
    font-style: italic;
    letter-spacing: 0;
    font-variant-caps: normal;
    text-transform: none;
    margin-left: 0.25em;
  }
}

//  Homepage Section Layout
.section {
  min-height: 100vh;
}

.section--about {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.section--portfolio {
  .figure {
    padding-bottom: rem-calc(200);
  }
}

/* Footer */

.footer {
  background-color: #314043;
  
  * {
    color: $light-gray;
  }
}

.footer-nav {
  min-height: 12em;
  padding: 1em 0 2em 0;
}

.footer-copyright {

}

/* Typography */

* {
  word-wrap: break-word;
}

body {
  font-feature-settings: 'liga', 'kern', 'onum';
}

.top-bar-title a {
  // font-family: $header-font-family;
  font-size: 1.5rem;
  // font-variant-caps: all-small-caps;
  // letter-spacing: 0.05em;
}

.about-content h1 {
  font-size: rem-calc(48);
  font-weight: 400;
}

.section--portfolio {
  .section-title {
    padding-bottom: 1em;
  }

  figure {
    h3,
    p {
      font-family: $body-font-family;
      font-weight: $global-weight-normal;
      font-size: $global-font-size * 1.2;
      line-height: $global-lineheight;
      margin-bottom: 0;
    }
  }
}

.section-title {
  // font-size: rem-calc(200);
  line-height: 1;
  color: $medium-gray;
  text-transform: lowercase;
}

abbr,
.small-caps {
  font-variant-caps: all-small-caps;
  letter-spacing: 0.03em;
  cursor: text;
  text-decoration: none;
  border: none;
}

.thumbnail img {
  //  max-height: 80vh;
}

.off-canvas-branding a {
  font-family: $body-font-family;
  font-weight: $global-weight-normal;
  font-size: $global-font-size * 1.2;
  // text-transform: uppercase;
  // letter-spacing: 0.12em;
}

.off-canvas-nav a {
  line-height: $global-lineheight * 1.1;  
  text-transform: none;
}
